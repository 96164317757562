<template>
  <div class="addmatch">
    <!-- 返回上一页 -->
    <div class="goback">
      <el-button type="danger" @click="close">关闭</el-button>
    </div>
    <div class="stepOne" v-if="stepOneShow">
      <div class="items">
        <span class="title">比赛名称：</span>
        <el-input
          v-model="matchParams.matchName"
          placeholder="请输入内容"
        ></el-input>
      </div>
      <div class="items">
        <span class="title">比赛用图：</span>
        <img
          v-if="matchParams.matchImg"
          :src="matchParams.matchImg"
          class="avatar"
        />
        <el-upload
          class="upload-demo"
          drag
          multiple
          ref="imageUpload"
          :auto-upload="false"
          action="https://jbvote.cdcee.com/common/uploadStatic"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :headers="headerToken"
          :on-change="handleChange"
          v-else
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">（建议尺寸360*180）</div>
        </el-upload>
      </div>
      <div class="items">
        <span class="title">报名时间：</span>
        <el-date-picker
          v-model="enroll"
          type="datetimerange"
          range-separator="-"
          start-placeholder="起始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <div class="items">
        <span class="title">大众评审：</span>
        <el-date-picker
          v-model="masses"
          type="datetimerange"
          range-separator="-"
          start-placeholder="起始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <div class="items">
        <span class="title">专家评审：</span>
        <el-date-picker
          v-model="expert"
          type="datetimerange"
          range-separator="-"
          start-placeholder="起始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <div class="items">
        <span class="title">结果公示：</span>
        <el-date-picker
          v-model="result"
          type="datetimerange"
          range-separator="-"
          start-placeholder="起始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <div class="items single">
        <span class="title">比赛结束：</span>
        <el-date-picker
          v-model="matchParams.matchEnd"
          type="datetime"
          placeholder="选择日期时间"
          default-time="12:00:00"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <div class="items">
        <span class="title">专家选择：</span>
        <div class="expertlist">
          <el-checkbox-group v-model="matchParams.reviewerIds" size="small">
            <el-checkbox
              :label="item.id"
              border
              v-for="(item, index) in expertlist"
              :key="index"
              >{{ item.presEntityName }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="items weight">
        <span class="title">权重设置：</span>
        <div class="weightSet">
          <div class="weightSet-items">
            <span class="title">大众评审</span>
            <el-input
              v-model="matchParams.publicWeight"
              type="number"
            ></el-input>
          </div>
          <div class="weightSet-items">
            <span class="title">专家评审</span>
            <el-input
              v-model="matchParams.expertWeight"
              type="number"
            ></el-input>
          </div>
          <span style="color: #606266; font-size: 12px"
            ><span style="color: red; margin: 0; font-size: 12px"
              >*总权重100%*</span
            >权重代表了评审方在评审中所占的比例</span
          >
        </div>
      </div>
      <div class="items">
        <span class="title">参赛说明：</span>
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入内容"
          v-model="matchParams.matchDesc"
        >
        </el-input>
      </div>
    </div>
    <div class="stepTwo" v-else>
      <div class="items input">
        <span class="title">总分值：</span>
        <el-input type="number" v-model="scoreTotal" disabled></el-input>
      </div>
      <div class="table">
        <div
          class="table-items"
          v-for="(item, index) in matchParams.matchCriteria"
          :key="index"
        >
          <div class="left">标准{{ index + 1 }}</div>
          <div class="right">
            <div class="right-top">
              <div class="right-top-one">核心主题</div>
              <div class="right-top-two">
                <el-input
                  v-model="item.criteriaTopic"
                  placeholder="请输入核心主题"
                ></el-input>
              </div>
              <div class="right-top-three">分值</div>
              <div class="right-top-four">
                <el-input type="number" v-model="item.score"></el-input>
              </div>
            </div>
            <div class="right-bottom">
              <div class="right-bottom-one">评分细则</div>
              <div class="right-bottom-two">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入评分细则"
                  v-model="item.criteriaDesc"
                >
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="addstandard">
        <el-button @click="addstandard">增加标准</el-button>
      </div>
    </div>
    <div class="next">
      <el-button type="primary" @click="nextStep" v-if="stepOneShow"
        >下一步</el-button
      >
      <div class="setpbut" v-else>
        <el-button type="info" @click="lastStep">上一步</el-button>
        <el-button type="primary" @click="endStep">完成创建</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      matchParams: {
        matchName: "", //名称
        matchImg: "", //图片
        regBegin: "", //报名时间开始时间
        regEnd: "", //报名时间结束时间
        publicReviewBegin: "", //大众开始时间
        publicReviewEnd: "", //大众结束时间
        expertReviewBegin: "", //专家开始时间
        expertReviewEnd: "", //专家结束时间
        resultStart: "", //公示结果开始时间
        resultEnd: "", //公示结果结束时间
        matchEnd: "", // 竞赛结束时间
        reviewerIds: [], // 专家id
        publicWeight: 0, // 大众权重
        expertWeight: 0, // 专家权重
        matchDesc: "", // 参赛说明
        matchCriteria: [],
      },
      token: window.localStorage.getItem("access_token"),
      enroll: "", // 报名时间选择
      masses: "", // 大众评审时间选择
      expert: "", // 专家评审时间选择
      result: "", // 结果公示时间选择
      expertlist: [],
      massesValue: "", // 大众评审权重
      expertValue: "", // 专家评审权重
      textarea: "", // 参赛说明,
      stepOneShow: true, // 第一步
      id: "",
    };
  },
  computed: {
    headerToken() {
      return { authentication: localStorage.getItem("access_token") };
    },
    scoreTotal() {
      let arr = this.matchParams.matchCriteria;
      var num = 0;
      for (let i = 0; i < arr.length; i++) {
        num += Number(arr[i].score);
      }
      console.log(num);
      return num;
    },
  },
  methods: {
    // 关闭该页面
    close() {
      this.$router.go(-1);
    },
    handleAvatarSuccess(res) {
      this.matchParams.matchImg = res.data;
    },
    // 获取专家列表
    getExpert() {
      this.$axiosGet("userManager/getExpertList", {}).then((res) => {
        console.log(res);
        for (const item of res.data) {
          item.check = false;
        }
        this.expertlist = res.data;
      });
    },
    // 增加标准
    addstandard() {
      this.matchParams.matchCriteria.push({
        criteriaName: "",
        criteriaTopic: "",
        score: 0,
        criteriaDesc: "",
      });
    },
    // 点击下一步
    nextStep() {
      this.stepOneShow = false;
      //  报名时间赋值
      this.matchParams.regBegin = this.enroll[0];
      this.matchParams.regEnd = this.enroll[1];
      //  大众时间赋值
      this.matchParams.publicReviewBegin = this.masses[0];
      this.matchParams.publicReviewEnd = this.masses[1];
      //  专家时间赋值
      this.matchParams.expertReviewBegin = this.expert[0];
      this.matchParams.expertReviewEnd = this.expert[1];
      //  公示结果赋值
      this.matchParams.resultStart = this.result[0];
      this.matchParams.resultEnd = this.result[1];
      console.log(this.matchParams, "第一步提交");
    },
    // 点击上一步
    lastStep() {
      this.stepOneShow = true;
      this.getinfo();
    },
    // 完成创建
    endStep() {
      let url = this.id ? "matchManager/updateMatch" : "matchManager/saveMatch";
      this.$axiosPost(url, this.matchParams).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$router.go(-1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取当前比赛详情
    getinfo() {
      this.$axiosGet("matchManager/queryDetail", { id: this.id }).then(
        (res) => {
          console.log(res);
          this.matchParams = res.data;
          //  报名时间赋值
          this.enroll = [this.matchParams.regBegin, this.matchParams.regEnd];
          //  大众时间赋值
          this.expert = [
            this.matchParams.publicReviewBegin,
            this.matchParams.publicReviewEnd,
          ];
          //  专家时间赋值
          this.masses = [
            this.matchParams.expertReviewBegin,
            this.matchParams.expertReviewEnd,
          ];
          //  公示结果赋值
          this.result = [
            this.matchParams.resultStart,
            this.matchParams.resultEnd,
          ];
          //  激活专家选中状态
          let arr = [];
          for (const item of res.data.reviewers) {
            arr.push(item.id);
          }
          this.matchParams.reviewerIds = arr;
          console.log(arr, 555);
        }
      );
    },
    // 修改文件名为时间戳
    handleChange(file) {
      let fileArr = file.name.split(".");
      let name = new Date().getTime();
      let fileType = fileArr[1];
      let f = new File([file.raw], name + "." + fileType);
      f.uid = file.uid; // new File 没有uid属性，会导致组件底层报错，这里手动加上
      file.raw = f; // 用f替换file的数据
      this.$refs.imageUpload.submit(); // 手动触发上传
    },
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id) this.getinfo();
    this.getExpert();
  },
};
</script>
<style lang="less" scoped>
.addmatch {
  background: #fff;
  // width: 60%;
  padding: 30px;
  position: relative;

  .goback {
    position: absolute;
    right: 10px;
    top: 10px;

    .el-button {
      padding: 8px 20px;
    }
  }

  .stepOne,
  .stepTwo {
    .items {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .title {
        color: #606266;
        font-size: 16px;
        display: inline-block;
        margin-right: 20px;
        width: 100px;
      }

      .el-input {
        width: 400px;
      }

      /deep/.el-input__inner {
        height: 36px;
        font-size: 14px;
        padding: 0 10px;
        width: 400px;
      }

      /deep/.el-input__prefix {
        display: none;
      }

      /deep/.el-textarea__inner {
        width: 400px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
      .avatar {
        width: 360px;
        height: 180px;
      }
      .expertlist {
        /deep/.el-checkbox {
          margin-right: 0;
        }
      }
    }

    .single {
      /deep/.el-input__inner {
        width: 200px;
      }
    }

    .weight {
      align-items: flex-start;

      /deep/.el-input__inner {
        font-size: 14px;
        padding: 0 10px;
        width: 100px;
        height: 30px;
      }

      .weightSet-items {
        margin-bottom: 20px;

        span {
          font-size: 14px;
          width: 60px;
        }
      }
    }
    .input {
      /deep/.el-input__inner {
        width: 100px;
      }
      .title {
        width: 50rpx !important;
        margin-right: 0 !important;
      }
    }

    .table {
      width: 80%;

      &-items {
        display: flex;
        align-items: center;
        border: 1px solid #606266;

        &:not(:first-child) {
          border-top: none;
        }

        > div {
          height: 176px;
        }

        .left {
          flex: 1;
          border-right: 1px solid #606266;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #606266;
        }

        .right {
          flex: 8;

          /deep/.el-input__inner {
            border: none;
          }

          /deep/.el-textarea__inner {
            border: none;
            resize: none;
          }

          &-top {
            border-bottom: 1px solid #606266;

            &-one {
              width: 100px;
            }

            &-two {
              flex: 5;
            }

            &-three {
              flex: 1;
            }

            &-four {
              flex: 1;
            }

            > div:not(:last-child) {
              border-right: 1px solid #606266;
              height: 70px;
            }
          }

          &-bottom {
            height: 60%;

            &-one {
              width: 100px;
            }

            &-two {
              flex: 6;
            }

            > div:not(:last-child) {
              border-right: 1px solid #606266;
              height: 105px;
            }
          }

          > div {
            display: flex;
            align-items: center;
            color: #606266;

            > div {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    .addstandard {
      margin: 30px 0 60px 0;
    }
  }

  .next {
    position: relative;

    .el-button {
      position: relative;
      left: 40%;
      transform: translate(-40%, 0);
      top: 0;
      margin-right: 30px;
    }
  }
}
</style>